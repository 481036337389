import React, { MutableRefObject, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

// third party components
import { Menu as HMenu } from '@headlessui/react'

import Link from 'next/link'

// styles
import styles from './index.module.scss'
import { useRouter } from 'next/router'

const ExploreMenu = props => {
  const { activeList, filterList, performerList, categoryList } = props
  const dispatch = useDispatch()
  const router = useRouter()
  const menuRef = useRef<HTMLElement | null>(null)
  const itemRef = useRef<HTMLElement | null>(null)
  const [itemsToShow, setItemsToShow] = React.useState(6)

  const [isMenuOpen, setIsMenuOpen] = React.useState(false) // Initially menu is hidden

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen) // Toggle the current boolean value
  }

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      itemRef.current &&
      !itemRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const toggleItems = () => {
    if (itemsToShow === 6) {
      setItemsToShow(50)
    } else {
      setItemsToShow(6)
    }
  }

  const displayItems = categoryList.slice(0, itemsToShow)

  return (
    <div className="flex items-center">
      <HMenu>
        <HMenu.Button onClick={toggleMenu} ref={itemRef as MutableRefObject<HTMLButtonElement>}>
          <div className={styles.container}>
            <div>{props.label}</div>
          </div>
        </HMenu.Button>
        {isMenuOpen && (
          <div ref={menuRef as MutableRefObject<HTMLDivElement>} className={styles.menuArea}>
            <div className="flex flex-row justify-between w-full gap-[60px] px-[27px] py-[12px] h-full">
              <div className="flex flex-col gap-4">
                <div className={styles.title}>Shop</div>
                <div className="flex flex-col gap-4 font-normal">
                  {activeList?.map((item, index) => (
                    <Link key={index} href={item.query === 'studio_merch' ? '/studio' : '/explore'} passHref>
                      <a>
                        <div
                          className={styles.text}
                          onClick={() => {
                            setIsMenuOpen(false)
                            dispatch({ type: 'set', exploreTab: 0 })
                            if (item.query === 'live_auctions') {
                              dispatch({ type: 'set', availabilityFilter: 1 })
                            } else if (item.query === 'live_editions') {
                              dispatch({ type: 'set', availabilityFilter: 2 })
                            } else {
                              dispatch({ type: 'set', availabilityFilter: 0 })
                            }
                            if (item.query === 'studio_merch') router.push('/studio')
                            else router.push('/explore')
                          }}
                        >
                          {item.label}
                        </div>
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className={styles.title}>Discover</div>
                <div className="flex flex-col gap-4 font-normal">
                  {filterList?.map((item, index) => (
                    <Link key={index} href={`/explore`} passHref>
                      <a>
                        <p
                          className={styles.text}
                          onClick={() => {
                            setIsMenuOpen(false)
                            dispatch({ type: 'set', exploreTab: index })
                            router.push('/explore')
                          }}
                        >
                          {item.label}
                        </p>
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className={styles.title}>Leaderboard</div>
                <div className="flex flex-col gap-4 font-normal">
                  {performerList?.map((item, index) => (
                    <Link key={index} href={`/stats`} passHref>
                      <a>
                        <p
                          className={styles.text}
                          onClick={() => {
                            setIsMenuOpen(false)
                            dispatch({ type: 'set', activeTab: index })
                            router.push('/stats')
                          }}
                        >
                          {item.label}
                        </p>
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col h-full gap-4">
                <div className={styles.title}>Categories</div>
                <div className="flex flex-col flex-grow-0 h-full overflow-auto gap-4 font-normal">
                  {displayItems?.map((item, index) => (
                    <Link key={index} href={`/category/${item.query.toLowerCase()}`} passHref>
                      <a>
                        <p
                          className={styles.text}
                          onClick={() => {
                            setIsMenuOpen(false)
                          }}
                        >
                          {item.label}
                        </p>
                      </a>
                    </Link>
                  ))}
                </div>
                <button className="flex justify-start" onClick={toggleItems}>
                  <div className={styles.menuItem}>{itemsToShow === 6 ? '+ Show More' : '- Show Less'} </div>
                </button>
              </div>
            </div>
          </div>
        )}
        {/* </Transition> */}
      </HMenu>
    </div>
  )
}

export default ExploreMenu
